import React, { useState, useEffect } from 'react';
import './FAQ.css';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { useTranslation } from "react-i18next";

const FAQ = () => {
    const { t, i18n } = useTranslation('translation');
    const [ direction, setDirection ] = useState('ltr');

    const [ firstQuestion, setFirstQuestion ] = useState(false);
    const handleFirstQuestion = () => setFirstQuestion(!firstQuestion);
    const [ secondQuestion, setSecondQuestion ] = useState(false);
    const handleSecondQuestion = () => setSecondQuestion(!secondQuestion);
    const [ thirdQuestion, setThirdQuestion ] = useState(false);
    const handleThirdQuestion = () => setThirdQuestion(!thirdQuestion);
    const [ fourthQuestion, setFourthQuestion ] = useState(false);
    const handleFourthQuestion = () => setFourthQuestion(!fourthQuestion);
    const [ fifthQuestion, setFifthQuestion ] = useState(false);
    const handleFifthQuestion = () => setFifthQuestion(!fifthQuestion);
    const [ sixthQuestion, setSixthQuestion ] = useState(false);
    const handleSixthQuestion = () => setSixthQuestion(!sixthQuestion);

    const scrollToForm = () => {
        const formElement = document.getElementById('registerForm');

        if (formElement) {
            formElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.languages, i18n.language])

    return (
        <>
	    	<div className='faqView' dir={direction}>
                <h1>{t('FAQ')}</h1>
                <div className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'faqContainerRight' : 'faqContainerLeft'} onClick={handleFirstQuestion}>
                    <div className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'questionContainerRight' : 'questionContainerLeft'}>
                        <h3>{t('FIRST_QUESTION')}</h3>
                        {firstQuestion ? <FaChevronUp className='faqContainerIcon' /> : <FaChevronDown className='faqContainerIcon' />}
                    </div>
                    {firstQuestion && <p>{t('FIRST_ANSWER')}</p>}
                </div>
                <div className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'faqContainerRight' : 'faqContainerLeft'} onClick={handleSecondQuestion}>
                    <div className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'questionContainerRight' : 'questionContainerLeft'}>
                        <h3>{t('SECOND_QUESTION')}</h3>
                        {secondQuestion ? <FaChevronUp className='faqContainerIcon' /> : <FaChevronDown className='faqContainerIcon' />}
                    </div>
                    {secondQuestion && <p>{t('SECOND_ANSWER')}</p>}
                </div>
                <div className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'faqContainerRight' : 'faqContainerLeft'} onClick={handleThirdQuestion}>
                    <div className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'questionContainerRight' : 'questionContainerLeft'}>
                        <h3>{t('THIRD_QUESTION')}</h3>
                        {thirdQuestion ? <FaChevronUp className='faqContainerIcon' /> : <FaChevronDown className='faqContainerIcon' />}
                    </div>
                    {thirdQuestion && <p>{t('THIRD_ANSWER')}</p>}
                </div>
                <div className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'faqContainerRight' : 'faqContainerLeft'} onClick={handleFourthQuestion}>
                    <div className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'questionContainerRight' : 'questionContainerLeft'}>
                        <h3>{t('FOURTH_QUESTION')}</h3>
                        {fourthQuestion ? <FaChevronUp className='faqContainerIcon' /> : <FaChevronDown className='faqContainerIcon' />}
                    </div>
                    {fourthQuestion && <p>{t('FOURTH_ANSWER')}</p>}
                </div>
                <div className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'faqContainerRight' : 'faqContainerLeft'} onClick={handleFifthQuestion}>
                    <div className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'questionContainerRight' : 'questionContainerLeft'}>
                        <h3>{t('FIFTH_QUESTION')}</h3>
                        {fifthQuestion ? <FaChevronUp className='faqContainerIcon' /> : <FaChevronDown className='faqContainerIcon' />}
                    </div>
                    {fifthQuestion && <p>{t('FIFTH_ANSWER')}</p>}
                </div>
                <div className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'faqContainerRight' : 'faqContainerLeft'} onClick={handleSixthQuestion}>
                    <div className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'questionContainerRight' : 'questionContainerLeft'}>
                        <h3>{t('SIXTH_QUESTION')}</h3>
                        {sixthQuestion ? <FaChevronUp className='faqContainerIcon' /> : <FaChevronDown className='faqContainerIcon' />}
                    </div>
                    {sixthQuestion && <p>{t('SIXTH_ANSWER')}</p>}
                </div>
                <p className='faqDescription'>{t('FAQ_FIRST_DESCRIPTION')}</p>
                <p className='registerButton' onClick={scrollToForm}>{t('FAQ_SECOND_DESCRIPTION')}</p>
	    	</div>
	    </>
    );
};

export default FAQ;
