import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cache from "i18next-localstorage-cache";
import postProcessor from "i18next-sprintf-postprocessor";

const Languages = ["de", "fa", "en", "ar", "kurk", "ru", "ta", "tr", "uk", "ps", "fr", "ti", "so", "da"];

i18n
    .use(Backend)
    .use(Cache)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(postProcessor)
    .init({
        lng: 'de',
        language: 'de',
        react: {
            useSuspense: false,
        },
        fallbackLng: Languages,
        preload: Languages,
        supportedLngs: Languages,
        debug: false,
        whitelist: Languages,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        nsSeperator: false,
        keySeperator: false,
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
    })
    .then(() => {
        // After initialization is complete, preload the translations for the user's language
        i18n.loadNamespaces(Languages);
    });

export default i18n;
