import React, { useState, useEffect, useRef } from 'react';
import './Register.css';
import axios from 'axios';
import cantons from './cantons.json'
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { Button, Menu, MenuItem, Typography, Modal } from "@material-ui/core";
import { has } from '../utils/isArray';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const RegisterForm = () => {
    const history = useHistory();
	const { t, i18n } = useTranslation('translation');
    const recaptchaRef = useRef(null);
    const location = useLocation();

	const [ anchorEl, setAnchorEl ] = useState(null);
	const [ language, setLanguage ] = useState('de');
    const [ languages, setLanguages ] = useState([]);
    const [ direction, setDirection ] = useState('ltr');
    const [ recaptchaLanguage, setRecaptchaLanguage ] = useState(i18n.language);
    const [ buttonStyle, setButtonStyle ] = useState({});

	const [ firstName, setFirstName ] = useState('');
	const [ firstNameError, setFirstNameError ] = useState(false);
	const [ lastName, setLastName ] = useState('');
	const [ lastNameError, setLastNameError ] = useState(false);
	const [ phoneNumber, setPhoneNumber ] = useState('');
	const [ phoneNumberError, setPhoneNumberError ] = useState(false);
	const [ canton, setCanton ] = useState('');
	const [ firstAgreement, setFirstAgreement ] = useState(false);
	const [ secondAgreement, setSecondAgreement ] = useState(false);
    const [ verified, setVerified ] = useState(null);
	const [ error, setError ] = useState(false);
    const [ showModal, setShowModal ] = useState(false);

    const handleModalClose = () => {
        setShowModal(false);
    };

	const firstNameHandleChange = event => {
        setFirstName(event);

        if (!event) {
            setFirstNameError(t('FIELD_REQUIRED'));
        } else {
            setFirstNameError('');
        }
    };

	const lastNameHandleChange = event => {
        setLastName(event);

        if (!event) {
            setLastNameError(t('FIELD_REQUIRED'));
        } else {
            setLastNameError('');
        }
    };

	const phoneNumberHandleChange = event => {
        setPhoneNumber(event);

        if (!event) {
            setPhoneNumberError(t('FIELD_REQUIRED'));
        } else {
            setPhoneNumberError('');
        }
    };

	const cantonHandleChange = event => {
        setCanton(event.target.value);
    };

	const firstAgreementHandleChange = () => {
        setFirstAgreement(!firstAgreement);
    };

	const secondAgreementHandleChange = () => {
        setSecondAgreement(!secondAgreement);
    };

	const handleSubmit = () => {
        const body = {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            canton: canton,
            language: i18n.language
        }

        axios.post('https://spiritdev-be.azurewebsites.net/api/ClientsRegister', body)
        .then(() => 
            console.log(''),
            setFirstName(''),
            setLastName(''),
            setPhoneNumber(''),
            setCanton(''),
		    setFirstAgreement(false),
		    setSecondAgreement(false),
            recaptchaRef.current.reset(),
            setError(true),
            setShowModal(true),
            setTimeout(() => {
                setError(false) }, 5000)
            ).catch(error => console.log(error))
    }

    function mapLanguageCode(language) {
        if (language === 'kurk' || language === 'da') {
            return 'en';
        } else {
            return language;
        }
    }

	useEffect(() => {
        setLanguage(i18n.language);
        setLanguages(i18n.languages);
        setRecaptchaLanguage(mapLanguageCode(i18n.language));

        if (i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }

        const handleResize = () => {
            setButtonStyle({
                fontSize: window.innerWidth <= 850 ? 14 : 16, 
                color: 'gray', 
                position: 'absolute', 
                borderWidth: 1, 
                borderStyle: 'solid', 
                right: (i18n.language === 'de' || i18n.language === 'en' || i18n.language === 'kurk' || i18n.language === 'ru' || i18n.language === 'ta' || i18n.language === 'tr' || i18n.language === 'uk' || i18n.language === 'fr' || i18n.language === 'ti') && (window.innerWidth <= 850 ? 20 : 0), 
                left: (i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da') && (window.innerWidth <= 850 ? 20 : 0), 
                top: 20,
                width: 'fit-content',
                paddingTop: 8,
                paddingBottom: 8
            });
        };
    
        handleResize();
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [i18n.languages, i18n.language])

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
        setAnchorEl(null);
        history.push(`/registry/${lng}`);
    };

	const handleClickButton = (event) => {
        setAnchorEl(event.currentTarget);
    };

	const handleClose = () => {
        setAnchorEl(null);
    };

    const Languages = [
        {
            key: 'de',
            label: t('DE'),
            img: '/german.png',
            lng: 'Deutsch'
        },
        {
            key: 'ar',
            label: t('AR'),
            img: '/arabic.png',
            lng: 'اَلْعَرَبِيَّة'
        },
        {
            key: 'uk',
            label: t('UK'),
            img: '/ukrainian.png',
            lng: 'Yкраїнська'
        },
        {
            key: 'fa',
            label: t('FA'),
            img: '/farsi.png',
            lng: 'فارسی'
        },
        {
            key: 'en',
            label: t('EN'),
            img: '/english.png',
            lng: 'English'
        },
        {
            key: 'kurk',
            label: t('KURK'),
            img: '/kurmanji.png',
            lng: 'Kurmancî'
        },
        {
            key: 'ru',
            label: t('RU'),
            img: '/russian.jpg',
            lng: 'Русский'
        },
        {
            key: 'ta',
            label: t('TA'),
            img: '/tamil.png',
            lng: 'தமிழ்'
        },
        {
            key: 'tr',
            label: t('TR'),
            img: '/turkish.png',
            lng: 'Türkçe'
        },
        {
            key: 'ps',
            label: t('PS'),
            img: '/pashto.png',
            lng: 'پښتو'
        },
        {
            key: 'fr',
            label: t('FR'),
            img: '/french.png',
            lng: 'Français'
        },
        {
            key: 'ti',
            label: t('TI'),
            img: '/tigrinya.png',
            lng: 'ትግርኛ'
        },
        // {
        //     key: 'so',
        //     label: t('SO'),
        //     img: '/sorani.png',
        //     lng: 'سۆرانی'
        // },
        {
            key: 'da',
            label: t('DA'),
            img: '/dari.png',
            lng: 'دری'
        }
    ]

  	return (
    	<>
			<div className='registerView' dir={direction}>
                <div className='logosContainer'>
                    <img className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'logoContentRight' : 'logoContentLeft'} src='/logo.jpg' alt='Team' />
                    <img className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'logoContentRight' : 'logoContentLeft'} src='/schweiz.png' alt='Team' />
                    <img className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'logoContentRight' : 'logoContentLeft'} src='/zurichh.jpg' alt='Team' />
                </div>
                <Button
                    style={buttonStyle}
                    aria-controls="lang-switch" aria-haspopup="true" onClick={handleClickButton}>
                    {location.pathname.includes('/so') ? (
                        <Typography style={{fontSize: window.innerWidth <= 1250 ? 14 : 12, textTransform: 'none'}}>سۆرانی</Typography>
                        ) : (
                        Languages.map((lang) => (
                            lang.key === language && (
                                <Typography style={{fontSize: window.innerWidth <= 1250 ? 14 : 12, textTransform: 'none'}}>{lang.lng}</Typography>
                            )
                        ))
                    )}
                    {Boolean(anchorEl) ?
                        <FaChevronUp sx={{ color: "#66717d" }} style={{marginRight: (i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da') && 7, marginLeft: (i18n.language === 'de' || i18n.language === 'en' || i18n.language === 'kurk' || i18n.language === 'ru' || i18n.language === 'ta' || i18n.language === 'tr' || i18n.language === 'uk' || i18n.language === 'fr' || i18n.language === 'ti') && 7}} />
                        :
                        <FaChevronDown sx={{ color: "#66717d" }} style={{marginRight: (i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da') && 7, marginLeft: (i18n.language === 'de' || i18n.language === 'en' || i18n.language === 'kurk' || i18n.language === 'ru' || i18n.language === 'ta' || i18n.language === 'tr' || i18n.language === 'uk' || i18n.language === 'fr' || i18n.language === 'ti') && 7}} />
                    }
                </Button>
                <Menu
                    id="lang-switch"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className='languageMenu'
                >
                    {has(Languages) && Languages.map((lang, index) => {
                        return <MenuItem key={index} onClick={() => changeLanguage(lang.key)} dir={direction}>
                            {/* <img
                                src={lang.img}
                                alt={lang.key}
                                style={{
                                    marginLeft: (i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da') && 10,
                                    marginRight: (i18n.language === 'de' || i18n.language === 'en' || i18n.language === 'kurk' || i18n.language === 'ru' || i18n.language === 'ta' || i18n.language === 'tr' || i18n.language === 'uk' || i18n.language === 'fr' || i18n.language === 'ti') && 10,
                                    height: '15px', width: '25px'
                                }}
                            /> */}
                            <Typography style={{fontSize: window.innerWidth <= 1250 ? 14 : 12}}>{lang.lng}</Typography>
                        </MenuItem>
                    })}
                </Menu>
				<h1>{t('TITLE')}</h1>
				<img src='/team.jpg' alt='Team' />
				<p id='registerForm'>{t('DESCRIPTION')}</p>
				<form className='registerForm'>
					<input className='firstNameInput' type='text' placeholder={t('FIRST_NAME') + ' *'} value={firstName} onChange={(event) => firstNameHandleChange(event.target.value)} required />
					{firstNameError && <span style={{color: 'red', position: 'absolute', left: (i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da') && 20, right: (i18n.language === 'de' || i18n.language === 'en' || i18n.language === 'kurk' || i18n.language === 'ru' || i18n.language === 'ta' || i18n.language === 'tr' || i18n.language === 'uk' || i18n.language === 'fr' || i18n.language === 'ti') && 20, top: 20}}>{firstNameError}</span>}
					<input className='lastNameInput' type='text' placeholder={t('LAST_NAME') + ' *'} value={lastName} onChange={(event) => lastNameHandleChange(event.target.value)} required />
					{lastNameError && <span style={{color: 'red', position: 'absolute', left: (i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da') && 20, right: (i18n.language === 'de' || i18n.language === 'en' || i18n.language === 'kurk' || i18n.language === 'ru' || i18n.language === 'ta' || i18n.language === 'tr' || i18n.language === 'uk' || i18n.language === 'fr' || i18n.language === 'ti') && 20, top: 80}}>{lastNameError}</span>}
					<input className='phoneNumberInput' type='text' placeholder={t('PHONE_NUMBER') + ' *'} value={phoneNumber} onChange={(event) => phoneNumberHandleChange(event.target.value)} required />
					{phoneNumberError && <span style={{color: 'red', position: 'absolute', left: (i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da') && 20, right: (i18n.language === 'de' || i18n.language === 'en' || i18n.language === 'kurk' || i18n.language === 'ru' || i18n.language === 'ta' || i18n.language === 'tr' || i18n.language === 'uk' || i18n.language === 'fr' || i18n.language === 'ti') && 20, top: 145}}>{phoneNumberError}</span>}
					<select value={canton} onChange={cantonHandleChange} required>
						<option value="" disabled selected>{t('KANTON') + ' *'}</option>
    	  				{cantons.map((canton, index) => (
    	  				  	<option key={index} value={canton}>
    	  				    	{t(canton)}
    	  				  	</option>
    	  				))}
    				</select>
					<label className='firstCheckboxInput'>
						<input className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'customCheckboxRight' : 'customCheckboxLeft'} type='checkbox' checked={firstAgreement} onChange={firstAgreementHandleChange} required />
						{t('FIRST_CHECKBOX_DESCRIPTION')}
					</label>
					<label className='secondCheckboxInput'>
						<input className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'customCheckboxRight' : 'customCheckboxLeft'} type='checkbox' checked={secondAgreement} onChange={secondAgreementHandleChange} required />
						{t('SECOND_CHECKBOX_DESCRIPTION')}
					</label>
					<ReCAPTCHA className='recaptcha' 
						sitekey='6LfDUzgpAAAAAOk_RwDRdbZfY0zAxgOqz31uQigb'
                        onChange={(value) => setVerified(value)}
                        hl={recaptchaLanguage}
                        key={recaptchaLanguage}
                        ref={recaptchaRef}
      				/>
					<button className={!firstName || !lastName || !phoneNumber || !canton || !!firstNameError || !!lastNameError || !!phoneNumberError || !firstAgreement || !secondAgreement || !verified ? 'registerFormDisableButton' : 'registerFormEnableButton'} 
						disabled={!firstName || !lastName || !phoneNumber || !canton || !!firstNameError || !!lastNameError || !!phoneNumberError || !firstAgreement || !secondAgreement || !verified} 
						onClick={handleSubmit} type='button'>
						{t('SAVE_BUTTON')}
					</button>
					<p>{error}</p>
				</form>
				<p>{t('FORM_DESCRIPTION')}</p>
			</div>
            <Modal className='modalContainer' open={showModal} onClose={handleModalClose} dir={direction}>
                <div className='modalContent'>
                    <button className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'closeButtonLeft' : 'closeButtonRight'} onClick={handleModalClose}>
                        &times;
                    </button>
                    <p className={i18n.language === 'ar' || i18n.language === 'fa' || i18n.language === 'ps' || i18n.language === 'so' || i18n.language === 'da' ? 'firstModalContentRight' : 'firstModalContentLeft'}>{t('SUCCESSFULLY_REGISTER_1')}</p>
                    <p className='secondModalContent'>{t('SUCCESSFULLY_REGISTER_2')} <br /> {t('SUCCESSFULLY_REGISTER_3')}</p>
                </div>
            </Modal>
		</>
  	);
};

export default RegisterForm;
