import React, { useEffect, useState } from 'react';
import i18n from 'i18next';

const TranslationLoader = ({ children }) => {
    const [translationsLoaded, setTranslationsLoaded] = useState(false);

    useEffect(() => {
        i18n.init().then(() => setTranslationsLoaded(true));
    }, []);

    return translationsLoaded ? children : <div>Loading...</div>;
};

export default TranslationLoader;
