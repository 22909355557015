import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import RegisterForm from './components/RegisterForm';
import FAQ from './components/FAQ';
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const languageFromUrl = window.location.pathname.split('/')[2];
    const validLanguages = ['de', 'ar', 'uk', 'fa', 'en', 'kurk', 'ru', 'ta', 'tr', 'pa', 'fr', 'ti', 'so', 'da'];
    const languageToSet = validLanguages.includes(languageFromUrl) ? languageFromUrl : 'de';
    i18n.changeLanguage(languageToSet);
  }, [i18n]);

  return (
    <Router>
      <Switch>
        <Route path="/registry/:language" render={(props) => {
          const languageFromRoute = props.match.params.language;
          
          if (!languageFromRoute) {
            return <Redirect to={`/registry/${i18n.language}`} />;
          }

          return (
            <>
              <RegisterForm />
              <FAQ />
            </>
          );
        }} />
        <Redirect from="/" to={`/registry/${i18n.language}`} />
      </Switch>
    </Router>
  );
}

export default App;
