import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import TranslationLoader from './TranslationLoader';
import i18n from './i18n';

i18n.init()
ReactDOM.render(
  <TranslationLoader>
    <App />
  </TranslationLoader>,
  document.getElementById('root')
);
